const NavBarData = (key) => {
  const aboutData = [
    { name: "Char Dham", path: "char-dham" },
    { name: "Dwadasha Lingams", path: "dwadasa-jyotirlingas" },
    { name: "Shakthi Peethas", path: "shakthi-peethas" },
  ];
  const aboutOverviewData = [
    {
      name: `About Us`,
      path: ``,
    },
    {
      name: `About CMD`,
      path: `CMD`,
    },
  ];

  const sevasAndDarshanamData = {
    pratyakshaseva: [
      { name: `Suprabhata Seva`, path: "sri-swamy-vari-vratham" },
      {
        name: `Sri Swamy vari Special Vratham`,
        path: "sri-swamy-vari-visishta-vratham",
      },
      { name: `Laksha Pathri Pooja`, path: "laksha-pathri-pooja" },
      { name: `Laksha Kumkumarchana`, path: "laksha-kumkumarchana" },
      { name: `Pratyangiri Homam`, path: "pratyangiri-homam" },
      {
        name: `Sri Swamy vari Nithya Kalyanam`,
        path: "sri-swamy-vari-nithya-kalyanam",
      },
      {
        name: `Sri Swamy vari Pavalimpu Seva`,
        path: "sri-swamy-vari-pavalimpu-seva",
      },
      { name: `Sri Swamy vari Abhishekam`, path: "sri-swamy-vari-abhishekam" },
      {
        name: `Sri Sita Ramula Pattabhishekam`,
        path: "sri-sita-ramula-pattabhishekam",
      },
      {
        name: `Sri Swamy vari Abhishekam - Makha Nakshatram`,
        path: "sri-swamy-vari-abhishekam",
      },
      { name: `Swarna Pushparchana`, path: "swarna-pushparchana" },
      { name: `Vedaseerwachanam`, path: "vedaseerwachanam" },
      { name: `Go Pooja`, path: "go-pooja" },
      { name: `Surya Namaskaramulu`, path: "surya-namaskaramulu" },
    ],
    parokshaseva: [
      {
        name: `Sri Satyanarayana Swamy vari Vratham`,
        path: "sri-satyanarayana-swamy-vari-vratham",
      },
      {
        name: `Sri Satyanarayana Swamy vari Kalyanam`,
        path: "sri-satyanarayana-swamy-vari-kalyanam",
      },
      { name: `Ayushya Homam`, path: "ayushya-homam" },
      { name: `Chandi Homam`, path: "chandi-homam" },
      { name: `Pratyangira Homam`, path: "pratyangira-homam" },
      {
        name: `Sri Kanaka Durga Amma vari Chandi Homam - Moola Nakshatram`,
        path: "sri-kanaka-durga-amma-vari-chandi-homam",
      },
      { name: `Surya Namaskaramulu`, path: "surya-namaskaramulu" },
      { name: `Sri Swamy vari Abhishekam`, path: "sri-swamy-vari-abhishekam" },
      { name: `Vedseerwachanam`, path: "Vedseerwachanam" },
    ],
    Darshanam: [
      { name: "Sri Swamy vari Darshanam", path: "sri-swamy-vari-darshanam" },
      {
        name: "Sri Swamy vari Seegra Darshanam",
        path: "sri-swamy-vari-seegra-darshanam",
      },
      {
        name: "Sri Swamy vari Yantralayam",
        path: "sri-swamy-vari-yantralayam",
      },
    ],
  };

  const donationsData = [
    { name: "e-Hundi", path: "e-hundi" },
    {
      name: "Sri Satya Deva Annaprasada Distribution Trust ",
      path: "sri-satya-deva-annaprasada-distribution-trust ",
    },
    {
      name: "Pulihora and Dadhojanam Scheme",
      path: "pulihora-and-dadhojanam-scheme",
    },
  ];

  const onlineBookingData = [
    // { name: "Darshanam Tickets", path: "darshanam" },
    { name: "Pratyaksha Seva Tickets", path: "pratyaksha-pooja" },
    { name: "Paroksha Seva Booking", path: "paroksha-pooja" },
    // { name: "Prasadam", path: "Prasadam" },
    // { name: "Accommodation", path: "Accommodation" },
    // { name: "Kalyana Katta Tickets", path: "kalyanakatta" },
    // { name: "Annadanam Tickets", path: "annadanam" },
    // { name: "Kalyana Mandapam Booking", path: "kalyana-mandapam" },
  ];

  const supportData = [
    { name: "FAQs", path: "faqs-all" },
    // { name: "Facilities to Pilgrims", path: "facilities-to-pilgrims" },
    // { name: "Connectivity", path: "connectivity" },
    // { name: "Live Chat", path: "live-chat" },
    { name: "Contact Us", path: "contact-us" },
  ];

  const mediaData = {
    gallery: [
      {
        name: `Photo Gallery`,
        path: `photo-gallery`,
      },
      // { name: "Video Gallery", path: "video-gallery" },
    ],
    "whats-new": [
      { name: "Temple Diary", path: "temple-diary" },
      { name: "Events", path: "events" },
      {
        name: `Newsletter`,
        path: "newsletter",
      },
    ],
    press: [
      { name: "Temple News", path: "temple-news" },
      { name: "Press Releases", path: "press-releases" },
      { name: "Press Coverage", path: "press-coverage" },
      { name: "Media Queries", path: "media-queries" },
    ],
  };

  const dataMap = {
    about: aboutData,
    "seva-darshanam": sevasAndDarshanamData,
    donation: donationsData,
    "online-data": onlineBookingData,
    media: mediaData,
    support: supportData,
    aboutOverview: aboutOverviewData,
  };

  return dataMap[key];
};

export default NavBarData;
