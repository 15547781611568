import React, { Suspense, lazy, useEffect } from "react";
import {
  Outlet,
  Route,
  Routes as SwitchRouter,
  useParams,
} from "react-router-dom";
import "./Utils/sd-styles/sd-app.scss";
import Sd_Home from "./pages/sd-home/sd-home";
import SD_Dashboard from "./pages/sd-dashboard";
import Sd_terms_and_conditions from "./pages/sd-misc/sd-terms-and-condotions";
import Sd_private_policy from "./pages/sd-misc/sd-private-policy";
import Sd_loading from "./Components/sd-common-components/sd-loading";
import { withRouter } from "./Utils/with-router/withRouter";
import { useState } from "react";
import Sd_about from "./pages/sd-about/sd-about";
import Sd_Char_Dham from "./pages/sd-about/sd-char-dham";
import Sd_Shakthi_Pettas from "./pages/sd-about/sd-shakthi-pettas";
import Sd_Dwadasa_Jyotirlingas from "./pages/sd-about/sd-dwadasa-jyotirlingas";
import Sd_about_cmd from "./Components/body/sd-about/sd-about-cmd";
import Sd_donors from "./pages/sd-donors/sd-donors";

//About---------------------

// );
const Sd_faqs_all = lazy(() =>
  import("./Components/sd-common-components/sd-faqs-all")
);

const Sd_support = lazy(() => import("./pages/sd-support/sd-support"));
//Temples
const TmsTemples = lazy(() => import("./pages/temples/tms-temples"));
const TmsTemplePage = lazy(() => import("./pages/temples/tms-temple-page"));
const TmsTemplesLive = lazy(() => import("./pages/temples/tms-temples-live"));

//trust

// const Sd_about_12_jyotirlinga = lazy(() =>
//   import("./pages/sd-about/sd-about-12-jyotirlinga")
// );
const Sd_about_18_shakthi_peethas = lazy(() =>
  import("./pages/sd-about/sd-about-18-shakthi-peethas")
);

//Sevas-and-darshanam

const Sd_gallery_full_view = lazy(() =>
  import("./Components/sd-common-components/sd-gallery-full-view")
);

const Sd_temple_dairy = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-temple-dairy")
);

//Online Booking

//Media Room
const Sd_comming_soon = lazy(() => import("./pages/sd-about/sd-coming-soon"));
const Sd_media_room_press_hoc = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-press/sd-media-room-press-hoc")
);

const Sd_media_room_tenders = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-tenders")
);
const Sd_media_room = lazy(() => import("./pages/sd-media-room/sd-media-room"));
const Sd_media_room_events = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-events")
);
const Sd_media_room_media_kit = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-media-kit")
);
const Sd_media_room_media_queries = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-media-queries")
);
const Sd_media_room_news = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-news")
);
const Sd_media_room_newsletter = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-newsletter")
);
const Sd_media_room_photo_gallery = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-photo-gallery")
);
const Sd_media_room_press_coverage = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-press-coverage")
);
const Sd_media_room_press_releases = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-press-releases")
);
const Sd_media_room_publications = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-publications")
);
const Sd_media_room_rti_act = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-rti-act")
);
const Sd_media_room_video_gallery = lazy(() =>
  import("./pages/sd-media-room/sd-media-room-video-gallery")
);

//cart
const Sd_search = lazy(() => import("./pages/sd-search/sd-search"));
const Sd_cart = lazy(() => import("./pages/sd-cart/sd-cart"));
//support
const Sd_support_call_center = lazy(() =>
  import("./pages/sd-support/sd-support-call-center")
);
const Sd_support_connectivity = lazy(() =>
  import("./pages/sd-support/sd-support-conectivity")
);
const Sd_support_contactUs = lazy(() =>
  import("./pages/sd-support/sd-support-contacUs")
);

const Sd_support_facilities_to_pilgrims = lazy(() =>
  import("./pages/sd-support/sd-support-facilities-to-pilgrims")
);
const Sd_support_live_chat = lazy(() =>
  import("./pages/sd-support/sd-support-live-chat")
);
const Sd_support_faqs = lazy(() =>
  import("./pages/sd-support/sd-support-faqs")
);
const SD_Login = lazy(() => import("./pages/sd-login-register-pages/sd-login"));
const SD_Signup = lazy(() =>
  import("./pages/sd-login-register-pages/sd-singup")
);

const SD_Assistance = lazy(() => import("./pages/sd-assistance"));
const SD_Reset = lazy(() => import("./pages/sd-login-register-pages/sd-reset"));
const SD_Verify_OTP = lazy(() =>
  import("./pages/sd-login-register-pages/sd-verify-otp")
);
const SD_Signup_Step_Two = lazy(() =>
  import("./pages/sd-login-register-pages/sd-register-step-two")
);
const SD_Dont_Remember = lazy(() =>
  import("./pages/sd-login-register-pages/sd-dont-remember")
);
// import {SD_Context} = lazy(() => import( "./context/sd-context";

const SD_Guest_SignUp = lazy(() =>
  import("./pages/sd-login-register-pages/sd-guest-login")
);
// import SD_Dashboard = lazy(() => import( "./pages/sd-dashboard";
const Sd_404 = lazy(() => import("./pages/sd-error-pages/sd-404"));

const Sd_about_the_temple = lazy(() =>
  import("./pages/sd-about/sd-about-the-temple")
);
const Sd_trademark = lazy(() => import("./pages/sd-misc/sd-trademarks"));
const Sd_faqs_Main = lazy(() => import("./pages/sd-help/sd-faqs"));
const Sd_Print_a_ticket = lazy(() =>
  import("./pages/sd-misc/sd-print-a-ticket")
);
const Sd_Values = lazy(() => import("./pages/sd-values/sd-values"));
const TmsAboutDepartment = lazy(() =>
  import("./pages/temples/tms-about-department")
);
const Sd_Routes = ({ router }) => {
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [loading, setLoading] = useState(true);
  let params = useParams();

  useEffect(() => {
    setLoading(false);
    console.log("---------validating routes-------");
    if (
      router?.location?.pathname?.replaceAll?.("/", "") === params?.lang &&
      supportedLanguages.includes(params?.lang)
    ) {
      console.log(params?.lang, router);
      setLoading(false);

      router.navigate(`/${params?.lang}/home`);
    }
    if (supportedLanguages.includes(params?.lang)) {
      console.log(params?.lang, router);

      setLoading(false);
    } else {
      console.log(params.lang, router);
      setLoading(false);
      router.navigate(`/en-in${router?.location?.pathname}`);
    }
  }, []);
  return (
    <Suspense fallback={<Sd_loading />}>
      {!loading && (
        <SwitchRouter>
          <Route path="home" element={<Sd_Home />} />
          <Route path="devotee-app/*" element={<SD_Dashboard />} />
          <Route path="devotee/signin" element={<SD_Login />} />
          <Route path="devotee/signup" element={<SD_Signup />} />
          <Route path="values/:type" element={<Sd_Values />} />

          <Route
            path="endownment-department"
            element={<TmsAboutDepartment />}
          />
          <Route
            path="media-room/press/:type"
            element={<Sd_media_room_press_hoc />}
          />
          <Route
            path="devotee/print-a-ticket"
            element={<Sd_Print_a_ticket />}
          />

          <Route path="coming-soon" element={<Sd_comming_soon />} />
          <Route
            path="about/the-temple/:type"
            element={<Sd_about_the_temple />}
          />

          <Route
            path="terms-and-conditions"
            element={<Sd_terms_and_conditions />}
          />
          <Route path="privacy-policy" element={<Sd_private_policy />} />
          <Route path="faqs/:type" element={<Sd_faqs_Main />} />
          <Route path="support/faqs-all" element={<Sd_faqs_all />} />
          <Route
            path="devotee/signup/verify"
            element={<SD_Signup_Step_Two />}
          />
          <Route
            path="media-room/photo-gallery"
            element={<Sd_gallery_full_view />}
          />
          <Route path="devotee/assistance" element={<SD_Assistance />} />
          <Route path="devotee/reset/verify/:num" element={<SD_Verify_OTP />} />
          <Route path="trademarks" element={<Sd_trademark />} />
          <Route path="devotee/reset" element={<SD_Reset />} />
          <Route path="devotee/reset/help" element={<SD_Dont_Remember />} />
          <Route path="guestLogin" element={<SD_Guest_SignUp />} />

          {/* <Route
          
          path="about/12-jyotirlingalu"
          element={Sd_about_12_jyotirlinga}
        />
        */}

          {/* <Route
          
          path="sevas-and-darshanam/athi-seegra-darshanam"
          element={Sd_sevas_and_darshanam_athi_seegra_darshanam}
        /> */}
          <Route
            path="temples/shakthi-peethas"
            element={<Sd_about_18_shakthi_peethas />}
          />
          <Route path="media-room" element={<Sd_media_room />} />
          <Route
            path="media-room/media-kit"
            element={<Sd_media_room_media_kit />}
          />
          <Route
            path="media-room/gallery/photo-gallery"
            element={<Sd_media_room_photo_gallery />}
          />
          <Route
            path="media-room/gallery/video-gallery"
            element={<Sd_media_room_video_gallery />}
          />
          <Route
            path="media-room/whats-new/events"
            element={<Sd_media_room_events />}
          />
          <Route path="about" element={<Sd_about />} />
          <Route path="about/CMD" element={<Sd_about_cmd />} />
          <Route path="about/char-dham" element={<Sd_Char_Dham />} />
          <Route path="about/shakthi-peethas" element={<Sd_Shakthi_Pettas />} />
          <Route
            path="about/dwadasa-jyotirlingas"
            element={<Sd_Dwadasa_Jyotirlingas />}
          />
          <Route path="donors" element={<Sd_donors />} />
          <Route
            path="media-room/whats-new/publications"
            element={<Sd_media_room_publications />}
          />
          <Route
            path="media-room/whats-new/temple-diary"
            element={<Sd_temple_dairy />}
          />
          <Route
            path="media-room/whats-new/newsletter"
            element={<Sd_media_room_newsletter />}
          />
          <Route
            path="media-room/tenders"
            element={<Sd_media_room_tenders />}
          />
          <Route
            path="media-room/rti-act"
            element={<Sd_media_room_rti_act />}
          />
          <Route
            path="media-room/press/press-releases"
            element={<Sd_media_room_press_releases />}
          />
          <Route
            path="media-room/press/temple-news"
            element={<Sd_media_room_news />}
          />
          <Route
            path="media-room/press/press-coverage"
            element={<Sd_media_room_press_coverage />}
          />
          <Route
            path="media-room/press/media-queries"
            element={<Sd_media_room_media_queries />}
          />

          <Route path="cart" element={<Sd_cart />} />
          <Route path="search" element={<Sd_search />} />
          <Route
            path="support/call-center"
            element={<Sd_support_call_center />}
          />
          <Route path="support/contact-us" element={<Sd_support_contactUs />} />
          <Route path="support/support-faqs" element={<Sd_support_faqs />} />
          <Route path="support/live-chat" element={<Sd_support_live_chat />} />
          <Route
            path="support/facilities-to-pilgrims"
            element={<Sd_support_facilities_to_pilgrims />}
          />
          <Route
            path="support/connectivity"
            element={<Sd_support_connectivity />}
          />
          <Route path="temples" element={<TmsTemples />} />
          <Route path="temples/:type/:page" element={<TmsTemplePage />} />
          <Route path="temples-live" element={<TmsTemplesLive />} />
          <Route path="support" element={<Sd_support />} />
          <Route path="*" element={<Sd_404 />} />
        </SwitchRouter>
      )}
      <Outlet />
    </Suspense>
  );
};
export default withRouter(Sd_Routes);
