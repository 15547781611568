import React from "react";
import { SD_divider, killi } from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-about.scss";

const Sd_about_cmd = () => {
  return (
    <div className="sd-about-one">
      <div
        className="sd-about-one-bottom flex-column"
        style={{ alignItems: "center" }}
      >
        <div className="sd-about-one-bottom-top">
          <h1 style={{ fontWeight: "600", fontSize: "30px" }}>About CMD</h1>
          <img
            style={{ height: "20px" }}
            src={SD_divider}
            alt={"divider"}
          ></img>
          <div
            className="sd-about-one-bottom-bottom"
            style={{ width: "100%", margin: "30px 0px" }}
          >
            <div className="sd-abb-three" style={{ maxWidth: "100%" }}>
              <div>
                <img src={killi} alt="" />
                <h2>K Satya Prasad Killi</h2>
                <p>CMD</p>
              </div>
            </div>
          </div>
          <div className="CMDdata">
            <p>
              Mr. Satya Prasad Killi, an Engineering and MCSE graduate, held
              executive positions in the salt-field and food-and-entertainment
              industries in the 1980s. Having migrated to the UK three decades
              ago, he gained diplomatic and networking experience by working
              under three successive Indian High Commissioners in London.
              Simultaneously, he engaged in charity work at the Bhavan Centre,
              UK, and later served as an Advisor to the Minister of State for
              Telecommunications & Information of Technology, Government of
              India, followed by a role as a Consultant for the Commonwealth in
              the UK.
            </p>
            <p>
              Widely known as Killi Garu among the Telugu diaspora in the UK,
              Mr. Satya Prasad Killi played a pivotal role in establishing the
              United Kingdom Telugu Association (UKTA) in 2010. As the honorary
              chairman of UKTA and a dedicated philanthropist, he successfully
              organised numerous charity and cultural programs in India, the UK,
              and other European countries. His wife, Dr. Venkata Padma Killy, a
              General Practitioner in the UK, serves as a trustee for UKTA.
            </p>
            <p>
              A dynamic individual, Mr. Killi, along with like-minded diplomats,
              bureaucrats, and intellectuals in Delhi, initiated the Devastanam
              Service (Worship from Home). This endeavour aims to virtually
              connect Hindu devotees worldwide to the Sevas performed in Indian
              temples.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sd_about_cmd;
