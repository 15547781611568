import React, { useEffect, useState } from "react";
import { withRouter } from "../../Utils/with-router/withRouter";
import { Amit_Gupta, SD_divider, killi, vamshi } from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-donors.scss";

const SdDonorMainComponent = ({ router }) => {
  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [type, setType] = useState("platinum");

  useEffect(() => {
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedlanguage(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);

  const donorList = {
    platinum: [
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "Vamshi", img: vamshi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
    ],
    diamond: [
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "Vamshi", img: vamshi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "Vamshi", img: vamshi },
    ],
    gold: [
      { name: "Amit", img: Amit_Gupta },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "Amit", img: Amit_Gupta },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
    ],
    silver: [
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
      { name: "K Satya Prasad Killi", img: killi },
    ],
  };

  const typeList = [
    { type: "Platinum", id: "platinum" },
    { type: "Diamond", id: "diamond" },
    { type: "Gold", id: "gold" },
    { type: "Silver", id: "silver" },
  ];

  return (
    <div
      className="sd-about-one"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div
        className="sd-about-one-bottom flex-column"
        style={{ alignItems: "center", maxWidth: "1100px" }}
      >
        <div className="sd-about-one-bottom-top">
          <h1 style={{ fontWeight: "600", fontSize: "30px" }}>
            Our Grateful Donors
          </h1>
          <img
            style={{ height: "20px" }}
            src={SD_divider}
            alt={"divider"}
          ></img>
        </div>
        <h3 style={{ fontSize: "14px", maxWidth: "800px" }}>
          We would like to express our deep appreciation to the individuals and
          organizations listed below for their generous contributions to
          SarvaDevasthanam Charitable Trust. Your support is instrumental in our
          efforts to maintain and enrich our sacred sanctuary.
        </h3>
        <div className="parentDivCat">
          <div className="categoryDiv">
            <div
              className={`backAnimation ${
                type === "platinum"
                  ? "animationTab1"
                  : type === "diamond"
                  ? "animationTab2"
                  : type === "gold"
                  ? "animationTab3"
                  : "animationTab4"
              }`}
            ></div>
            {typeList?.map((item, key) => {
              return (
                <div
                  key={key}
                  className={`categoryType ${
                    type === item?.id ? "categoryTypeActive" : ""
                  }`}
                  onClick={() => {
                    setType(item?.id);
                  }}
                >
                  {item?.type}
                </div>
              );
            })}
          </div>

          <div className="listDiv">
            {donorList[type]?.map((item, i) => {
              return (
                <div key={i} className="listItem">
                  <img src={item?.img} className="listImg" />
                  <p className="listName">{item?.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SdDonorMainComponent);
