import React, { useContext, useEffect, useState } from "react";
import {
  cellphone,
  facebook,
  insta,
  mail_icon,
  newspaper,
  SD_appstore_icon,
  SD_Call_Icon,
  SD_email_icon,
  sd_map_icon,
  SD_playstore_icon,
  SD_Web_Icon,
  send_icon,
  twitter,
  youtube,
} from "../../Assets/imageList";
import NewsletterSubService from "../../services/sd-newsletter-sub.service";
import "../../Utils/sd-styles/sd-footer.scss";
import "react-toastify/dist/ReactToastify.css";
import { SD_Context } from "../../context/sd-context";
import FooterData from "../../Utils/data/footer.data";
import { withRouter } from "../../Utils/with-router/withRouter";
// import { toast } from "react-toastify"; toast.configure()
// eslint-disable-next-line react/display-name
const Footer = React.memo(({ router }) => {
  //-------------------------------------------------------------states----------------------------------------------------------//

  const [selectedLanguage, setSelectedLanguages] = React.useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [errorFlag, setErrorFlag] = useState(false);
  const [subEmail, setSubEmail] = useState("");
  const [successFlag, setSuccessFlag] = useState(false);
  const { visitorCount, getVisitors } = useContext(SD_Context);
  const [errMsg, setMsg] = useState("");

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//

  const subscribeButton = async () => {
    try {
      if (subEmail) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(subEmail).toLowerCase())) {
          setErrorFlag(true);
          setMsg("Please enter a valid Email address");
          return;
        }
        let res = await NewsletterSubService.subscribe(subEmail, "APEDP");
        if (res) {
          setErrorFlag(false);
          setMsg("");
        }
        // toast.success("Subscribed to newsletter.");
        setSuccessFlag(true);
        setSubEmail("");
      } else {
        setErrorFlag(true);
      }
    } catch (error) {
      setMsg(error.message);
      setErrorFlag(true);

      // toast.error(error.message);
    }
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//
  useEffect(() => {
    if (
      supportedLanguages.includes(router?.location?.pathname?.split?.("/")?.[1])
    ) {
      setSelectedLanguages(router?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedLanguages("en-in");
    }
    getVisitors();
  }, []);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-footer-main">
      <div className="sd-footer-top">
        <div className="sd-footer-top-right">
          <p>
            <img src={newspaper} alt="=" />
            Subscribe Newsletter
          </p>
          <div className="sd-sub">
            <div
              className={
                errorFlag
                  ? "subscribe sd-red"
                  : subEmail !== ""
                  ? " sd-orange subscribe"
                  : "subscribe"
              }
            >
              <img src={mail_icon} alt="email icon" />

              <input
                type="text"
                maxLength="30"
                placeholder="Email Address"
                style={{ textTransform: "none" }}
                onKeyUp={(e) => {
                  if (e.keyCode === 13 || e.key === "Enter") {
                    subscribeButton();
                  }
                }}
                onChange={(e) => {
                  if (errorFlag) {
                    setErrorFlag(false);
                  }
                  setSubEmail(e.target.value);
                }}
                value={subEmail}
              />
              <img
                src={send_icon}
                onClick={subscribeButton}
                alt="newsletter icon"
              />
              {errorFlag && <h4>{errMsg}</h4>}
            </div>
          </div>
        </div>
        <div className="sd-footer-top-left">
          <p>
            {" "}
            <img src={cellphone} alt="=" />
            Download Mobile App
          </p>
          <div>
            <img
              src={SD_playstore_icon}
              alt="google play"
              style={{ marginLeft: "10px", marginRight: "10px" }}
            />
            <img src={SD_appstore_icon} alt="google play" />
          </div>
        </div>

        {successFlag && (
          <div className="callout">
            <div className="callout-header">Success</div>
            <span
              className="closebtn"
              onClick={() => {
                setSuccessFlag(false);
              }}
            >
              ×
            </span>
            <div className="callout-container">
              <p>
                Thanks for subscribing!{" "}
                {/* <a >Learn more</a> or close it if it is in your way. */}
              </p>
              <button
                onClick={() => {
                  setSuccessFlag(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="sd-footer-middle">
        <footer className="text-center text-lg-start sd-footer-middle-f">
          <div
            className="sd-container-r"
            style={{
              width: "90%!important",
            }}
          >
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "initial",
              }}
            >
              <div className="sd-list-body w-16">
                <h5
                  className="text"
                  onClick={() => {
                    router?.navigate(`/${selectedLanguage}/about`);
                  }}
                >
                  About
                </h5>
                <ul className="list-unstyled mb-0">
                  {/* <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        router?.navigate(`/${selectedLanguage}/about`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li> */}
                  {FooterData("about")?.map((data, i) => {
                    return (
                      <li key={i}>
                        <a
                          className="text-dark"
                          onClick={() => {
                            router?.navigate(
                              `/${selectedLanguage}/${data?.path}`
                            );
                          }}
                        >
                          {data?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="sd-list-body w-16">
                <h5 className="text">Temples</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        router?.navigate(`/${selectedLanguage}/temples`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  {FooterData("temples")?.map((data, i) => {
                    return (
                      <li key={i}>
                        <a
                          className="text-dark"
                          onClick={() => {
                            router?.navigate(
                              `/${selectedLanguage}/${
                                data.name === "Team" ? "" : "about/"
                              }${data?.path}`
                            );
                          }}
                        >
                          {data?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="sd-list-body w-16">
                <h5 className="text ">Book a pooja</h5>

                <ul className="list-unstyled">
                  {/* <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        // router?.navigate(`/${selectedLanguage}/online-booking`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li> */}
                  {FooterData("online-data")?.map((data, i) => {
                    return (
                      <li key={i}>
                        <a
                          className="text-dark"
                          onClick={() => {
                            if (localStorage.getItem("accessToken")) {
                              router?.navigate(
                                `/${selectedLanguage}/devotee-app/online-booking/${data?.path}`
                              );
                            } else {
                              router?.navigate(
                                `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/${data?.path}`
                              );
                            }
                          }}
                        >
                          {data?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="sd-list-body w-16">
                <h5 className="text ">Join a pooja</h5>

                <ul className="list-unstyled">
                  {/* <li>
                    <a
                      className="text-dark"
                      // onClick={() => {
                      //   router?.navigate(`/${selectedLanguage}/online-booking`);
                      // }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li> */}
                  {FooterData("online-data")?.map((data, i) => {
                    return (
                      <li key={i}>
                        <a
                          className="text-dark"
                          onClick={() => {
                            if (localStorage.getItem("accessToken")) {
                              router?.navigate(
                                `/${selectedLanguage}/devotee-app/join-a-pooja`
                              );
                            } else {
                              router?.navigate(
                                `/${selectedLanguage}/devotee/signin?to=devotee-app/join-a-pooja`
                              );
                            }
                          }}
                        >
                          {data?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="sd-list-body w-18">
                <h5 className="text ">Media Room</h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        router?.navigate(`/${selectedLanguage}/media-room`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  {FooterData("media")?.map((data, i) => {
                    return (
                      <li key={i}>
                        <a
                          onClick={() => {
                            router?.navigate(
                              `/${selectedLanguage}/media-room/${data?.path}`
                            );
                          }}
                          className="text-dark"
                        >
                          {data?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="sd-list-body w-15">
                <h5 className="text ">Support</h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        router?.navigate(`/${selectedLanguage}/support`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  {FooterData("support")?.map((data, i) => {
                    return (
                      <li key={i}>
                        <a
                          onClick={() => {
                            router?.navigate(
                              `/${selectedLanguage}/support/${data?.path}`
                            );
                          }}
                          className="text-dark"
                        >
                          {data?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className="sd-additional-footer">
            <div className="top">
              <p>
                <img src={sd_map_icon} alt="main" /> Sarvadevasthanam Hindu
                Charitable Trust, BD-21, Pitampura, New Delhi - 110034. India.
              </p>
            </div>
            <div className="bottom">
              <div className="left ">
                <p>
                  <img src={SD_Call_Icon} alt="number" /> +91 - 95821 40591,
                  96763 60256, 98490 05469
                </p>
                <p>
                  <img src={SD_email_icon} alt="mail" className="sep" />
                  contact@devasthanam.co.in
                </p>
                <p>
                  <img src={SD_Web_Icon} alt="mail" />
                  www.devasthanam.co.in
                </p>
              </div>

              <div className="right">
                <div className="left">
                  <p>
                    {" "}
                    Visitors Till Today <span>{visitorCount.total ?? 0}</span>
                  </p>{" "}
                </div>
                <div className="left">
                  <p>
                    Visitors Today <span>{visitorCount.today ?? 0}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>

      <div className="sd-footer-tag">
        <div className="middle">
          <p className="border-r">
            © {new Date().getFullYear()} Sarvadevasthanam Hindu Charitable
            Trust. All Rights Reserved.
          </p>
          <p
            onClick={() => {
              router?.navigate(`/${selectedLanguage}/privacy-policy`);
            }}
            className="border-r color"
          >
            Privacy Policy
          </p>

          <p
            onClick={() => {
              router?.navigate(`/${selectedLanguage}/terms-and-conditions`);
            }}
            className=" color"
          >
            Terms & Conditions
          </p>

          {/* <div className="left">
            <img
              onClick={() => {
                window.open(
                  "https://www.facebook.com/sriveeravenkatasatyanarayanaswamy",
                  "_blank"
                );
              }}
              src={facebook}
              alt="icon"
            />

            <img
              onClick={() => {
                window.open(
                  "https://youtube.com/c/AnnavaramDevasthanamLive",

                  "_blank"
                );
              }}
              src={youtube}
              alt="icon"
            />
            <img
              onClick={() => {
                window.open(
                  "https://twitter.com/AnnavaramDevas1?t=CMCZDtumNhJOWCcXQL26JQ&s=09",
                  "_blank"
                );
              }}
              src={twitter}
              alt="icon"
            />
            <img
              onClick={() => {
                window.open(
                  "https://twitter.com/AnnavaramDevas1?t=CMCZDtumNhJOWCcXQL26JQ&s=09",
                  "_blank"
                );
              }}
              src={insta}
              alt="icon"
            />
          </div> */}
        </div>
        <div
          className="right"
          onClick={() => {
            window.open("https://www.9and9.com/", "_blank");
          }}
        >
          <div className="left">
            <img
              // onClick={() => {
              //   window.open(
              //     "https://www.facebook.com/sriveeravenkatasatyanarayanaswamy",
              //     "_blank"
              //   );
              // }}
              src={facebook}
              alt="icon"
            />

            <img
              // onClick={() => {
              //   window.open(
              //     "https://youtube.com/c/AnnavaramDevasthanamLive",

              //     "_blank"
              //   );
              // }}
              src={youtube}
              alt="icon"
            />
            <img
              // onClick={() => {
              //   window.open(
              //     "https://twitter.com/AnnavaramDevas1?t=CMCZDtumNhJOWCcXQL26JQ&s=09",
              //     "_blank"
              //   );
              // }}
              src={twitter}
              alt="icon"
            />
            <img
              // onClick={() => {
              //   window.open(
              //     "https://twitter.com/AnnavaramDevas1?t=CMCZDtumNhJOWCcXQL26JQ&s=09",
              //     "_blank"
              //   );
              // }}
              src={insta}
              alt="icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
});
export default withRouter(Footer);
