const FooterData = (key) => {
  const about = [
    { name: "Temples", path: "temples" },
    // { name: "Trust Boards", path: "trust-boards" },
    // { name: "About department", path: "endownment-department" },
    // { name: "Testimonials", path: "" },
    { name: "About Us", path: "about" },
    { name: "About CMD", path: "about/CMD" },
  ];

  const values = [
    { name: "Hindu Dharmika Parishad", path: "hindu-dharmika-parishad" },
    { name: "Veda and Agama Patashala", path: "veda-and-agama-patashala" },
    { name: "Common Good Fund", path: "common-good-fund" },
    { name: "Dhoopa Deepa Naivedyam", path: "dhoopa-deepa-naivedyam" },
    { name: "Archaka Welfare Fund", path: "archaka-welfare-fund" },
    { name: "SITA", path: "sita" },
    // { name: "Founder Family Members", path: "founding-family-members" },
  ];

  const temples = [
    { name: "Chardham", path: "char-dham" },
    { name: "Jyothirlingas", path: "jyothirlingas" },
    { name: "Sakthi Peetas", path: "sakthi-peethas" },
    // { name: "Others", path: "others" },
  ];

  const onlineBooking = [
    { name: "Pratyaksha Pooja", path: "pratyaksha-pooja" },
    { name: "Paroksha Pooja", path: "paroksha-pooja" },
    // { name: "Kalyana Katta Tickets", path: "kalyanakatta" },
    // { name: "Tour Packages", path: "tour-packages" },
    // { name: "Publication", path: "Publication" },
  ];

  const mediaRoom = [
    // { name: "Events/Calender", path: "media-kit" },
    // { name: "Panchanagam", path: "panchangam" },
    // { name: "Daily Updates", path: "daily-updates" },
    // { name: "Press Releases", path: "press" },
    { name: "Gallery", path: "gallery" },
  ];
  const support = [
    { name: "FAQs", path: "faqs-all" },
    // { name: "Facilities to Pilgrims", path: "facilities-to-pilgrims" },
    // { name: "Connectivity", path: "connectivity" },
    // { name: "Live Chat", path: "live-chat" },
    { name: "Contact Us", path: "contact-us" },
  ];

  const dataMap = {
    about,
    values,
    temples,
    "online-data": onlineBooking,
    support,
    media: mediaRoom,
  };
  return dataMap[key];
};
export default FooterData;
