// import {FALSE} from "node-sass";
import React from "react";
import {
  facebook,
  insta,
  SD_cart_icon,
  SD_Dropdown_Arrow_icon,
  SD_Logo_circle,
  sd_minus_icon,
  sd_plus_icon,
  SD_Print_a_ticket_icon,
  twitter,
  youtube,
} from "../../Assets/imageList";
// import { toast } from "react-toastify"; toast.configure()
import { SD_Context } from "../../context/sd-context";
import NavBarData from "../../Utils/data/navbar.data";
import { withRouter } from "../../Utils/with-router/withRouter";
const Sd_mobile_left_sider = ({
  setSider,
  selectedLanguage,
  setDropTwo,
  drop2,
  setDropOne,
  drop4,
  drop3,
  drop7,
  setDropSeven,
  setDropFive,
  setDropThree,
  setDropFour,
  drop6,
  setDropSix,

  setDropTen,
  setDropNine,
  setDropEight,

  setDrop11,
  setDrop12,
  drop13,
  setDrop13,
  drop14,
  setDrop14,
  drop15,
  setDrop15,
  router,
}) => {
  const { language, setlanguage } = React.useContext(SD_Context);
  //-------------------------------------------------------------funtion-----------------------------------------------------------//
  const Route_push = () => {
    setDropOne(false);
    setDropTwo(false);
    setDrop11(false);
    setDrop13(false);
    setDrop12(false);
    setDropFive(false);
    setDropThree(false);
    setDropSix(false);
    setDropFour(false);
    setDropTen(false);
    setDropNine(false);
    setDropEight(false);
    setDropSeven(false);
    setDrop14(false);
    setDrop15(false);
    setSider(false);
  };

  //-------------------------------------------------------------funtion-----------------------------------------------------------//
  return (
    <div className="inner">
      <div className="inner-nav-top">
        <div className="left">
          <img
            className="logo"
            src={SD_Logo_circle}
            alt="icon"
            onClick={() => {
              Route_push();
              router?.navigate(`/${selectedLanguage}/home`);
            }}
          />
        </div>

        <div className="right">
          <div className="dropdown">
            <button
              className="btn"
              type="button"
              id="dropdownMenuButton1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {language.desc} <img src={SD_Dropdown_Arrow_icon} alt="icon" />
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
            >
              <a
                className="dropdown-item"
                onClick={() => {
                  setlanguage("ENGLISH");
                }}
              >
                ENGLISH
              </a>
              {/* <a
                className="dropdown-item"
                onClick={() => {
                  // setlanguage("TELUGU");
                }}
              >
                TELUGU
              </a>
              <a
                className="dropdown-item"
                onClick={() => {
                  // setlanguage("TAMIL");
                }}
              >
                TAMIL
              </a>
              <a
                className="dropdown-item"
                onClick={() => {
                  // setlanguage("KANNADA");
                }}
              >
                KANNADA
              </a>
              <a
                className="dropdown-item"
                onClick={() => {
                  // setlanguage("MARATHI");
                }}
              >
                MARATHI
              </a>
              <a
                className="dropdown-item"
                onClick={() => {
                  // setlanguage("HINDI");
                }}
              >
                HINDI
              </a> */}
            </div>
          </div>

          <img src={SD_cart_icon} alt={SD_cart_icon} />
          <button
            type="button"
            onClick={() => {
              setSider(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div className="inner-bottom">
        <div className="inner-bottom-t">
          <div
            onClick={() => {
              // Route_push();
              // router?.navigate(`/${selectedLanguage}/online-booking`);
            }}
          >
            <img src={SD_Print_a_ticket_icon} alt={SD_Print_a_ticket_icon} />
            PRINT A TICKET
          </div>
          {/* <div
            onClick={() => {
              Route_push();
              router?.navigate(`/${selectedLanguage}/volunteer`);
            }}
          >
            Join a Pooja
          </div>
          <div
            onClick={() => {
              Route_push();
              router?.navigate(`/${selectedLanguage}/shop`);
            }}
          >
            SHOP
          </div> */}
        </div>
        <div className="inner-bottom-b">
          <div
            className="between"
            onClick={() => {
              // Route_push();
              // router?.navigate(`/${selectedLanguage}/temples`);
              setDropThree(!drop3);
            }}
          >
            Temples
          </div>

          <div className={drop2 ? "drop-two block" : "drop-two none"}>
            {NavBarData("seva-darshanam")?.["parokshaseva"]?.map((data, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    Route_push();
                    router?.navigate(
                      `/${selectedLanguage}/sevas-and-darshanam/paroksha-pooja/${data?.path}`
                    );
                  }}
                >
                  {data?.name}
                </div>
              );
            })}

            {/* {praytakshaSevaNav.map((item, i) => {
              return (
                <div
                  key={"pratm" + i}
                  onClick={() => {
                    Route_push();

                    toast.info(
                      "Paroksha poojas info is not available at the moment."
                    );
                  }}
                >
                  {item?.key}{" "}
                </div>
              );
            })} */}
          </div>

          <div
            className="between"
            onClick={() => {
              Route_push();
              router?.navigate(`/${selectedLanguage}/join-a-pooja`);
            }}
          >
            Join a Pooja
            {/* {drop4 ? (
              <img src={sd_minus_icon} alt="-"></img>
            ) : (
              <img src={sd_plus_icon} alt="+" />
            )} */}
          </div>
          <div
            className="between"
            onClick={() => {
              Route_push();
              router?.navigate(`/${selectedLanguage}/book-a-pooja`);
            }}
          >
            Book a Pooja
            {/* {drop4 ? (
              <img src={sd_minus_icon} alt="-"></img>
            ) : (
              <img src={sd_plus_icon} alt="+" />
            )} */}
          </div>
          <div
            className="between"
            onClick={() => {
              setDropFour((prev) => !prev);
            }}
          >
            About
            {drop4 ? (
              <img src={sd_minus_icon} alt="-"></img>
            ) : (
              <img src={sd_plus_icon} alt="+" />
            )}
          </div>

          <div className={drop4 ? "drop-four block" : "drop-four none"}>
            {NavBarData("about")?.map((data, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    Route_push();

                    router?.navigate(
                      `/${selectedLanguage}/about/${data?.path}`
                    );
                  }}
                >
                  {data?.name}
                </div>
              );
            })}
          </div>

          <div
            className="between"
            onClick={() => {
              setDropSix(!drop6);
            }}
          >
            Media Room
            {drop6 ? (
              <img src={sd_minus_icon} alt="-"></img>
            ) : (
              <img src={sd_plus_icon} alt="+" />
            )}
          </div>

          <div className={drop6 ? "drop-six block" : "drop-six none"}>
            <div
              onClick={() => {
                Route_push();
                router?.navigate(`/${selectedLanguage}/media-room`);
              }}
            >
              Overview
            </div>
            <div
              onClick={() => {
                Route_push();

                router?.navigate(`/${selectedLanguage}/media-room/media-kit`);
              }}
            >
              Media Kit
            </div>
            <div>
              <span>Gallery </span>
              {drop13 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDrop13(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDrop13(true);
                  }}
                />
              )}
            </div>
            <div className={drop13 ? "drop-13 block" : "drop-13 none"}>
              {NavBarData("media")?.["gallery"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();
                      router?.navigate(
                        `/${selectedLanguage}/media-room/gallery/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
            </div>

            {/* <div>
              <span>Whats New </span>
              {drop14 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDrop14(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDrop14(true);
                  }}
                />
              )}
            </div> */}

            <div className={drop14 ? "drop-14 block" : "drop-14 none"}>
              {NavBarData("media")?.["whats-new"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();

                      router?.navigate(
                        `/${selectedLanguage}/media-room/whats-new/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
              <div
                onClick={() => {
                  Route_push();

                  router?.navigate(
                    `/${selectedLanguage}/media-room/whats-new/temple-diary`
                  );
                }}
              >
                Temple Dairy{" "}
              </div>
            </div>

            {/* <div>
              <span
                onClick={() => {
                  // Route_push();
                  // router?.navigate(`/${selectedLanguage}/media-room/press`);
                }}
              >
                Press{" "}
              </span>
              {drop15 ? (
                <img
                  src={sd_minus_icon}
                  alt="-"
                  onClick={() => {
                    setDrop15(false);
                  }}
                ></img>
              ) : (
                <img
                  src={sd_plus_icon}
                  onClick={() => {
                    setDrop15(true);
                  }}
                />
              )}
            </div> */}

            <div className={drop15 ? "drop-15 block" : "drop-15 none"}>
              {NavBarData("media")?.["press"]?.map((data, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      Route_push();

                      router?.navigate(
                        `/${selectedLanguage}/media-room/press/${data?.path}`
                      );
                    }}
                  >
                    {data?.name}
                  </div>
                );
              })}
            </div>
            {/* <div>
              <span
                onClick={() => {
                  Route_push();
                  router?.navigate(`/${selectedLanguage}/media-room/rti-act`);
                }}
              >
                RTI Act{" "}
              </span>
            </div> */}
          </div>

          <div
            className="between"
            onClick={() => {
              setDropSeven(!drop7);
              Route_push();

              router.navigate(`/${selectedLanguage}/support/contact-us`);
            }}
          >
            Support
          </div>
          {/* <div
            className={drop7 ? "drop-seven block" : "drop-seven none"}
            style={{ marginBottom: "50px" }}
          >
            <div
              onClick={() => {
                Route_push();

                router?.navigate(`/${selectedLanguage}/support`);
              }}
            >
              Overview
            </div>
            {NavBarData("support")?.map((data, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    Route_push();
                    router?.navigate(
                      `/${selectedLanguage}/support/${data?.path}`
                    );
                  }}
                >
                  {data?.name}
                </div>
              );
            })}
          </div> */}
        </div>
      </div>
      <div className="inner-footer">
        <img
          onClick={() => {
            window.open(
              "https://www.facebook.com/devasthanam.co.in/",
              "_blank"
            );
          }}
          src={facebook}
          alt="icon"
        />
        <img
          onClick={() => {
            window.open("https://twitter.com/DevasthanamCo", "_blank");
          }}
          src={twitter}
          alt="icon"
        />

        <img
          onClick={() => {
            window.open(
              "https://www.instagram.com/devasthanam.co.in/",
              "_blank"
            );
          }}
          src={insta}
          alt="icon"
        />
        <img
          onClick={() => {
            window.open(
              "https://www.youtube.com/channel/UCBBTjPJ0TLCaLskvFXC7QBQ",

              "_blank"
            );
          }}
          src={youtube}
          alt="icon"
        />
      </div>
    </div>
  );
};
export default withRouter(Sd_mobile_left_sider);
