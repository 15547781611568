import React from "react";
import { SD_divider } from "../../Assets/imageList";
import SD_Paroksha_Seva_One from "../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
// import Sd_breadcrumb from "../../Components/sd-common-components/sd-breadcrumb";

const Sd_private_policy = () => {
  return (
    <div>
      <SD_Paroksha_Seva_One from="Privacy Policy" />

      {/* <Sd_breadcrumb from="Privacy Policy"></Sd_breadcrumb> */}
      <div className="sd-t-and-c">
        <div className="sd-t-and-c-inner">
          <div className="top white-bc">
            <h1>Privacy Policy</h1>
            <img
              src={SD_divider}
              alt={SD_divider}
              style={{ height: "20px" }}
            ></img>
          </div>
          <>
            <br />
            {/* <span style={{ paddingLeft: "0px" }}>
              Last Updated: September 20, 2022
            </span> */}
            <br />
            <br />
            Protecting your privacy is important to us. The following policy
            provides information on we collect, use, and safeguard the personal
            information you provide on our website. This policy may change from
            time to time. Please reread this policy periodically. When you
            browse the website or mobile platforms, we do not collect personal
            information. We collect certain non- personally identifiable
            information automatically such as your internet service provider's
            address, the web page you are redirected from, and a record of your
            activity on our site. This Privacy Statement discloses our privacy
            practices and how it treats the information that we collect and
            receive, including information related to any service, products and
            facilities on this website. We collect information about your
            computer and assigns you a cookie; when you visit our website.
            <br />
            <br />
            Cookies are unique numbers assigned, which is transferred to a
            cookie file on your computer's hard drive. Cookies enable the
            systems to recognize you when you visit website and make it easier
            for you to use the website. We also collect Name,Mobile Number
            (Mobile Verification), Date of Birth, Gender, Gothram, Email (Email
            Verification), Profile Photo, Address, ID Proof which will help us
            to ensure our services are working as intended, and to provide
            efficient services to you. And further use the information to make
            improvements of services offered. We will never share devotes
            personal information with companies, organizations, or individuals
            outside or sell the data collected with any third parties. We ask
            for Devotees consent before using Devotees information for a purpose
            that isn’t covered in this Privacy Policy. We may share information
            for legal purposes with the enforcement authorities that meet any
            applicable law, regulation, legal process, or enforceable
            governmental request. We restrict access to personal information to
            our employees, contractors, and agents who need that information in
            order to process it. Anyone with this access is subject to strict
            contractual confidentiality obligations and may be disciplined or
            terminated if they fail to meet these obligations.
            <br />
            <br />
            Presently, we do not provide any third party advertisement on the
            website/mobile platform. No third party interference is allowed and
            hence there is no question of collecting any information from third
            party from the website/Mobile Platform. In future, if we allow
            advertisement on this website with authorized permission, we will
            decide on the collection of private information by third party or
            use of cookies by third party. The necessary information will be
            mentioned in this place. We value the trust you place in us. That's
            why we insist upon the highest standards for secure transactions and
            information privacy. Please read the following statement to learn
            about our information gathering and dissemination practices
            <br />
            <br />
            We view protection of your privacy as a very important principle. We
            understand clearly that You and Your Personal Information is one of
            our most important assets. We store and process your Information
            including any sensitive financial information collected (as defined
            under the Information Technology Act, 2000), if any, on computers
            that may be protected by physical as well as reasonable
            technological security measures and procedures in accordance with
            Information Technology Act 2000 and Rules there under. If you object
            to Your Information being transferred or used in this way please do
            not use this Website / Mobile App.
            <br />
            <br />
            We and our affiliates will not share / sell / transfer / license /
            covey some or all of your personal information with another business
            entity should we (or our assets) plan to merge with or are acquired
            by that business entity, or re-organization, amalgamation,
            restructuring of business or for any other reason whatsoever. Should
            such a transaction or situation occur, the other business entity or
            the new combined entity will be required to follow the privacy
            policy with respect to your personal information. Once you provide
            your information to us, you provide such information to us and our
            affiliate and we and our affiliate may use such information to
            provide you various services with respect to your transaction
            whether such transaction are conducted on the Website / Mobile App
            or with third party merchant's or third party merchant's Website /
            Mobile App.
          </>
          <br />
          <br />
          <b>Note:</b>
          <br />
          Our privacy policy is subject to change at any time without notice. To
          make sure you are aware of any changes, please review this policy
          periodically. By visiting this Website / Mobile App you agree to be
          bound by the terms and conditions of this Privacy Policy. If you do
          not agree please do not use or access our Website / Mobile App.
          <br />
          <br />
          By mere use of the Website / Mobile App, you expressly consent to our
          use and disclosure of your personal information in accordance with
          this Privacy Policy. This Privacy Policy is incorporated into and
          subject to the Terms of Use.
          <br />
          <br />
          <span>1.</span> Collection of Personally Identifiable Information and
          other Information When you use our Website / Mobile App, we collect
          and store your personal information which is provided by you from time
          to time. Our primary goal in doing so is to provide you a safe,
          efficient, smooth and customized experience. This allows us to provide
          services and features that most likely meet your needs, and to
          customize our Website / Mobile App to make your experience safer and
          easier. More importantly, while doing so we collect personal
          information from you that we consider necessary for this purpose.
          <br />
          <br />
          In general, you can browse the Website / Mobile App without telling us
          who you are or revealing any personal information about yourself. Once
          you give us your personal information, you are not anonymous to us.
          Where possible, we indicate which fields are required and which fields
          are optional. You always have the option to not provide information by
          choosing not to use a particular service or feature on the Website /
          Mobile App. We may automatically track certain information about you
          based upon your behaviour on our Website / Mobile App. We use this
          information to do internal research on our users' demographics,
          interests, and behaviour to better understand, protect and serve our
          users. This information is compiled and analysed on an aggregated
          basis. This information may include the URL that you just came from
          (whether this URL is on our Website / Mobile App or not), which URL
          you next go to (whether this URL is on our Website / Mobile App or
          not), your computer browser information, and your IP address.
          <br />
          <br />
          In general, you can browse the Website / Mobile App without telling us
          who you are or revealing any personal information about yourself. Once
          you give us your personal information, you are not anonymous to us.
          Where possible, we indicate which fields are required and which fields
          are optional. You always have the option to not provide information by
          choosing not to use a particular service or feature on the Website /
          Mobile App. We may automatically track certain information about you
          based upon your behaviour on our Website / Mobile App. We use this
          information to do internal research on our users' demographics,
          interests, and behaviour to better understand, protect and serve our
          users. This information is compiled and analysed on an aggregated
          basis. This information may include the URL that you just came from
          (whether this URL is on our Website / Mobile App or not), which URL
          you next go to (whether this URL is on our Website / Mobile App or
          not), your computer browser information, and your IP address.
          <br />
          <br />
          We also use cookies to allow you to enter your password less
          frequently during a session. Cookies can also help us provide
          information that is targeted to your interests. Most cookies are
          "session cookies," meaning that they are automatically deleted from
          your hard drive at the end of a session. You are always free to
          decline our cookies if your browser permits, although in that case you
          may not be able to use certain features on the Website / Mobile App
          and you may be required to re-enter your password more frequently
          during a session.
          <br />
          <br />
          Additionally, you may encounter "cookies" or other similar devices on
          certain pages of the Website / Mobile App that are placed by third
          parties. We do not control the use of cookies by third parties.
          <br />
          <br />
          If you choose to buy on the Website / Mobile App, we collect
          information about your buying behaviour.
          <br />
          <br />
          If you transact with us, we collect some additional information, such
          as a billing address, a credit / debit card number and a credit /
          debit card expiration date and/ or other payment instrument details
          and tracking information from cheques or money orders.
          <br />
          <br />
          If you choose to post messages on our message boards, chat rooms or
          other message areas or leave feedback, we will collect that
          information you provide to us. We retain this information as necessary
          to resolve disputes and provide support. If you send us personal
          correspondence, such as emails or letters, or if other users or third
          parties send us correspondence about your activities or postings on
          the Website / Mobile App, we may collect such information into a file
          specific to you. We collect personally identifiable information (email
          address, name, phone number, credit card / debit card / other payment
          instrument details, etc.) from you when you set up a free account with
          us. While you can browse some sections of our Website / Mobile App
          without being a registered member, certain activities (such as placing
          an order for a service) do require registration. We do use your
          contact information to send you offers based on your previous orders
          and your interests.
          <br />
          <br />
          <span>2.</span>
          Use of Demographic / Profile Data / Your Information We use personal
          information to provide the services you request. To the extent we use
          your personal information to market to you, we will provide you the
          ability to opt-out of such uses. We use your personal information to
          resolve disputes; troubleshoot problems; help promote a safe service;
          collect money; measure consumer interest in our products and services,
          inform you about online and offline offers, products, services, and
          updates; customize your experience; detect gather broad demographic
          information and protect us against error, fraud and other criminal
          activity; enforce our terms and conditions; and as otherwise described
          to you at the time of collection. In our efforts to continually
          improve our product and service offerings, we collect and analyse
          demographic and profile data about our users' activity on our Website
          / Mobile App. We identify and use your IP address to help diagnose
          problems with our server, and to administer our Website / Mobile App.
          Your IP address is also used to help identify you. We will
          occasionally ask you to complete optional online surveys. These
          surveys may ask you for contact information and demographic
          information (like zip code, age, or income level). We use this data to
          tailor your experience at our Website / Mobile App, providing you with
          and displaying content that we think you might be interested in.
          <br />
          <br />
          <span>3.</span>
          Cookies A "cookie" is a small piece of information stored by a web
          server on a web browser so it can be later read back from that
          browser. Cookies are useful for enabling the browser to remember
          information specific to a given user. We place both permanent and
          temporary cookies in your computer's hard drive. The cookies do not
          contain any of your personally identifiable information.
          <br />
          <br />
          <span>4.</span>Sharing of personal information We may share personal
          information with our other temple entities and affiliates to help
          detect and prevent identity theft, fraud and other potentially illegal
          acts; correlate related or multiple accounts to prevent abuse of our
          services; and to facilitate joint or co-branded services that you
          request where such services are provided by more than one corporate
          entity. Those entities and affiliates may not market to you as a
          result of such sharing unless you explicitly opt-in. We may disclose
          personal information if required to do so by law or in the good faith
          belief that such disclosure is reasonably necessary to respond to
          subpoenas, court orders, or other legal process. We may disclose
          personal information to law enforcement offices, third party rights
          owners, or others in the good faith belief that such disclosure is
          reasonably necessary to: enforce our Terms or Privacy Policy; respond
          to claims that an advertisement, posting or other content violates the
          rights of a third party; or protect the rights, property or personal
          safety of our users or the public.
          <br />
          <br />
          This Privacy Policy may change, and use of information that we gather
          now is subject to the Privacy Policy that is in effect at of the time
          of use. You are responsible for periodically checking our Privacy
          Statement. You may opt-out of any posted change to our policy, use or
          disclosure of Devote personal information by sending communication to
          us. If we make minor changes to this Privacy Policy, the changes will
          be immediately effective upon posting the revised Privacy Policy to
          the website/mobile platform under this terms and conditions. If we
          make material changes to this Privacy Policy or in how it will use or
          collect Devotes Personally Identifiable Information, we will
          prominently post a notification on website of the changed Privacy
          Policy ten (10) days prior to implementing the change. The date at the
          top of this page is the date that the Privacy Policy’s effective date.
          <br />
          <br />
          In case of any complaint or clarifications please approach us at
        </div>
      </div>
    </div>
  );
};
export default Sd_private_policy;
