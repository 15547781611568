import React from "react";
import "../../Utils/sd-styles/sd-misc.scss";
// import Sd_breadcrumb from "../../Components/sd-common-components/sd-breadcrumb";
import ParokshaSevaOne from "../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
import { SD_divider } from "../../Assets/imageList";
const Sd_terms_and_conditions = () => {
  return (
    <div>
      {/* <Sd_breadcrumb from="Terms and Conditions"></Sd_breadcrumb> */}
      <ParokshaSevaOne from="Terms and Conditions" />
      <div className="sd-t-and-c">
        <div className="sd-t-and-c-inner">
          {/* <h2></h2> */}
          <div className="top white-bc">
            <h1>Terms and Conditions</h1>
            <img
              src={SD_divider}
              alt={SD_divider}
              style={{ height: "20px" }}
            ></img>
          </div>
          <b>
            <h4>Copyright:</h4>
          </b>
          <br />
          <p>
            This website is the copyright of AP Technology Services Limited. All
            rights are reserved. Temple Management System (Herein after called
            as TMS) respects your feedback. Any information, including
            suggestions, questions, comments, creative ideas, graphics, or other
            materials submitted to Temple under AP Endowments Department, shall
            be treated as non-confidential, unless otherwise stated. You agree
            that you will not send any copyrighted information to us. Physical
            reprints of material from the site are NOT permitted.
          </p>
          <p>
            Website content is subject to change without notice and at the sole
            editorial discretion of the TMS. Other websites may not copy pages
            or articles. No artwork or images or graphics may be copied to
            another website without prior written permission
          </p>
          <p>
            To make any comments or for enquiries, write to:{" "}
            <a href="mailto: endow-edpsec@gov.in"> endow-edpsec@gov.in</a>
          </p>
          <br />
          <b>
            <h4>Disclaimer:</h4>
          </b>
          <br />
          <p>
            Thank you for visiting <a>www.tms.ap.gov.in</a> , a portal of TMS by
            AP Endowments Department, Government of Andhra Pradesh. By viewing
            this portal, you agree to the terms and conditions specified at the
            bottom of the page. TMS may update the terms and conditions without
            prior notice. Please read them from time to time, as your continued
            use of the site means that you accept the changes. Upon acceptance
            of the terms and conditions, permission is granted for you to view,
            copy, print or download material on this site. You may not modify,
            license, transfer or sell any information obtained from this portal.
            Any use of information from this portal must credit to AP Endowments
            Department, Government of Andhra Pradesh, India. The website
            <a> www.tms.ap.gov.in </a> is for informational purposes only and
            its content is subject to change without notice. Inclusion of
            information regarding a product or service on this website does not
            imply endorsement of it by the TMS. Links to websites are provided
            solely for the convenience of users. The Linked Sites are not under
            the control of The TMS and the TMS is not responsible for the
            contents of any Linked Site including, without limitation, any link
            contained in a Linked Site or any changes or updates to a Linked
            Site. The TMS does not offer any guarantee in that regard and is not
            responsible for the information found through these links, nor does
            it endorse the sites and their contents. In no event shall the TMS,
            its members and suppliers be liable for any direct, indirect,
            punitive, incidental, special, consequential damages or any damages
            whatsoever including, without limitation, damages for loss of use,
            data or profits, arising out of or in any way connected with the use
            or delay or inability to use its website or Linked Sites, the
            provision of or failure to provide services, or for any information,
            products or services obtained through the sites or Linked Sites or
            otherwise arising out of the use of the site or Linked Sites whether
            based on contract, tort, negligence, strict liability or otherwise,
            even if TMS, its members and suppliers have been advised of the
            possibility of damages.
          </p>
          <p>
            If you are not satisfied with any portion of the site or with any of
            these terms and conditions, your sole and exclusive remedy is to
            discontinue using the site. While the TMS follows specific
            procedures to ensure the accuracy and reliability of its website
            content, your use of it is at your sole risk. The TMS does not
            assume any liability deemed to have been caused directly or
            indirectly by any content or use of a tool on its website. You agree
            to abide by these terms and conditions, and also to comply with all
            local rules, regulations and laws regarding online conduct and
            acceptable content.{" "}
          </p>
          <p>
            While registering or creating profile on TMS portal, it is construed
            that we will be saving Mobile number in our Database to keep
            pilgrims updated on various events, special programmes etc., at
            temples, as appropriate.
          </p>
          <br />
          <b>
            <h4>Refund / Cancellation Policy:</h4>
          </b>
          <br />
          <p>
            Transactions which are successfully debited by the payment gateway
            but not confirmed back to TMS system / respective temples are deemed
            to be failed transactions. All such transactions are eligible for
            refund post reconciliation. In such transactions, money is refunded
            in 12-15 working days.
          </p>
          <p>
            All bookings are FINAL.
            <span style={{ color: "#ff0000" }}>
              <b>
                Cancellation is strictly not allowed for successful
                transactions.
              </b>
            </span>
          </p>
          <p>
            For enquiries on refunds, write to
            <a href="mailto: endow-edpsec@gov.in"> endow-edpsec@gov.in</a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Sd_terms_and_conditions;
